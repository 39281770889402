import React from "react";
//import { Route } from "react-router-dom";
 import { ClientsLoadingDialog } from "./clients-loading-dialog/ClientsLoadingDialog";
// import { ProductDeleteDialog } from "./product-delete-dialog/ProductDeleteDialog";
// import { ProductsDeleteDialog } from "./products-delete-dialog/ProductsDeleteDialog";
// import { ProductsFetchDialog } from "./products-fetch-dialog/ProductsFetchDialog";
// import { ProductsUpdateStatusDialog } from "./products-update-status-dialog/ProductsUpdateStatusDialog";
 import { ClientsCard } from "./ClientsCard";
import { ClientsUIProvider } from "./ClientsUIContext";

export function ClientsPage({ history }) {
  const clientsUIEvents = {
    newClientButtonClick: () => {
      history.push("/clients/new");
    },
    openEditClientDialog: (id) => {
      history.push(`/clients/${id}/edit`);
    },
    openDeleteClientDialog: (id) => {
      history.push(`/clients/${id}/delete`);
    },
    openDeleteClientsDialog: () => {
      history.push(`/clients/deleteClients`);
    },
    openFetchClientsDialog: () => {
      history.push(`/clients/fetch`);
    },
  };

  return (
    <ClientsUIProvider clientsUIEvents={clientsUIEvents}>
          <ClientsLoadingDialog />



          <ClientsCard />
    </ClientsUIProvider>
  );
}
