import React
//{ useEffect } 
from "react";
//import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";

export function ClientsLoadingDialog() {
  // Clients Redux state
  // const { isLoading } = useSelector(
  //   (state) => ({ isLoading: state.clients.listLoading }),
  //   shallowEqual
  // );
  // looking for loading/dispatch
  //useEffect(() => {}, [false]);
  return <LoadingDialog isLoading={false} text="Loading ..." />;
}
