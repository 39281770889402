import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useClientsUIContext } from "../ClientsUIContext";

const prepareFilter = (queryParams, values) => {
  const {  searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  if (searchText) {
    filter.name = searchText;
    filter.mname = searchText;
    filter.lname = searchText;
    filter.phone = searchText;
    filter.email = searchText;
    filter.addresses = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ClientsFilter({ listLoading }) {
  // Clients UI Context
  const clientsUIContext = useClientsUIContext();
  const clientsUIProps = useMemo(() => {
    return {
      queryParams: clientsUIContext.queryParams,
      setQueryParams: clientsUIContext.setQueryParams,
    };
  }, [clientsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(clientsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, clientsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      clientsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">

              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Търсене</b> във всички полета
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
